import React from "react"
import { graphql } from "gatsby"
import List from "../components/list"

const BlogListTemplate = ({ data, pageContext, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const pageCount = pageContext.pageCount
  const currentPage = pageContext.currentPage

  return (
    <List
      posts={posts}
      location={location}
      siteTitle={siteTitle}
      pageCount={pageCount}
      currentPage={currentPage}
    />
  )
}

export default BlogListTemplate
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { status: { ne: "writing" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date
            title
            description
          }
        }
      }
    }
  }
`
